@use "@angular/material" as mat;
@use "../utils/colors";

@mixin light-cube-mat-theme($theme) {
  @include default-cube-mat-theme($theme);
}

@mixin dark-cube-mat-theme($theme) {
  @include default-cube-mat-theme($theme);

  .table {
    th,
    tr {
      background-color: mat.get-color-from-palette(map-get($theme, primary), 500);
      color: #ffffff;
    }
  }
}

@mixin default-cube-mat-theme($theme) {
  .mat-mdc-chip {
    background-color: mat.get-color-from-palette(map-get($theme, primary), 500) !important;
  }
}
