@use "@angular/material" as mat;
@use "../utils/colors";
@use "../themes/light-theme";
@use "../themes/dark-theme";

@mixin light-cube-sidemenu-theme($theme) {
  @include default-cube-sidemenu-theme($theme);

  .sidenav {
    background-image: linear-gradient(colors.color-get(light-theme.$light-theme-accent, 500, 1), colors.color-get(light-theme.$light-theme-foreground, 500, 1));
  }
}

@mixin dark-cube-sidemenu-theme($theme) {
  @include default-cube-sidemenu-theme($theme);

  .sidenav {
    background-image: linear-gradient(colors.color-get(dark-theme.$dark-theme-primary, 400, 1), colors.color-get(dark-theme.$dark-theme-primary, 500, 1));
  }
}

@mixin default-cube-sidemenu-theme($theme) {
  .sidemenu {
    height: calc(100vh - 64px);
    article {
      width: 100%;
    }
  }

  .version {
    color: white;
    font-size: smaller;
    margin: 0 0 10px;
  }

  .sidemenu-container {
    min-height: 100%;

    .sidemenu-item-list-container {
      .mdc-list {
        overflow: hidden;
        padding: 0;

        &.primary {
          transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
        }

        .mdc-list-item {
          font-size: 14px;
        }
      }
    }
  }

  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .sidenav-dropdown-indicator {
    transition: transform 0.25s;

    &.indicateOpen {
      transform: rotate(180deg);
    }
  }

  mat-nav-list {
    overflow: hidden;
    padding-top: 0;
  }

  .primary {
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0;
  }

  .secondaryMenu {
    background: mat.get-color-from-palette($primary, 300);
    transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
    padding-top: 0;
  }

  .mat-nav-list .mat-list-item .mat-list-icon {
    @include mat-list-icon-theme(20px);
    color: white;
  }

  .mat-chip:not(.mat-basic-chip) {
    @include mat-chip-theme();
  }

  .mat-nav-list .mat-list-item {
    font-size: 14px;
    color: white;
  }

  .active {
    border-left: 3px solid #fff;
  }
}

@mixin mat-list-icon-theme($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  color: white;
}

@mixin mat-chip-theme() {
  padding: 1px 7px;
}
