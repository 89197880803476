/* Sort the properties inside utilities based on css property name  */
$utilities: (
  // CSS property which will be modified by the class
  "font-weight":
    (
      // Class name prefix - to be created dynamically
      "prefix": "font-weight",
      // Class name suffix (value) - to be created dynamically -
      "values":
        (
          "300": 300,
          "500": 500,
          "600": 600,
        )
    ),
  "gap": (
    "prefix": "gap",
    "values": (
      "10": 10px,
      "14": 14px,
      "15": 15px,
      "20": 20px,
      "30": 30px,
    ),
  ),
  "height": (
    "prefix": "height",
    "values": (
      "600": 600px,
      "300": 300px,
      "100": 100%,
    ),
  ),
  "margin": (
    "prefix": "margin",
    "values": (
      "0": 0px,
      "10": 10px,
      "13": 13px,
      "14": 14px,
      "20": 20px,
      "0-5": 0 5px,
      // left & right - 5px
      "0-10": 0 10px,
      "30-0": 30px 0,
      //top & bottom - 30px
      "10-0": 10px 0,
    ),
  ),
  "margin-bottom": (
    "prefix": "margin-bottom",
    "values": (
      "10": 10px,
      "15": 15px,
      "20": 20px,
      "40": 40px,
      "80": 80px,
    ),
  ),
  "margin-left": (
    "prefix": "margin-left",
    "values": (
      "20": 20px,
    ),
  ),
  "margin-right": (
    "prefix": "margin-right",
    "values": (
      "5": 5px,
      "12": 12px,
      "20": 20px,
    ),
  ),
  "margin-top": (
    "prefix": "margin-top",
    "values": (
      "0": 0px,
      "10": 10px,
      "15": 15px,
      "50": 50px,
    ),
  ),
  "min-width": (
    "prefix": "min-width",
    "values": (
      "100": 100px,
      "120": 120px,
      "125": 125px,
      "130": 130px,
      "150": 150px,
      "175": 175px,
    ),
  ),
  "padding": (
    "prefix": "padding",
    "values": (
      "3": 3px,
      "10": 10px,
      "20": 20px,
      "30": 30px,
    ),
  ),
  "padding-bottom": (
    "prefix": "padding-bottom",
    "values": (
      "20": 20px,
      "40": 40px,
    ),
  ),
  "padding-left": (
    "prefix": "padding-left",
    "values": (
      "10": 10px,
      "15": 15px,
      "20": 20px,
    ),
  ),
  "padding-right": (
    "prefix": "padding-right",
    "values": (
      "0": 0px,
      "10": 10px,
      "13": 13px,
      "15": 15px,
      "20": 20px,
    ),
  ),
  "padding-top": (
    "prefix": "padding-top",
    "values": (
      "20": 20px,
    ),
  ),
  "width": (
    "prefix": "width",
    "values": (
      "25": 25%,
      "75": 75%,
      "80": 80%,
      "90": 90%,
      "100": 100%,
    ),
  )
);

//generate utility classes
@each $property, $map in $utilities {
  $prefix: map-get($map, "prefix");
  $values: map-get($map, "values");

  @each $k, $v in $values {
    @if ($k == "default") {
      .#{$prefix} {
        #{$property}: $v;
      }
    } @else {
      .#{$prefix}-#{$k} {
        #{$property}: $v;
      }
    }
  }
}
