/* You can add global styles to this file, and also import other style files */

@use "./assets/scss/utils/flexbox";
@use "./assets/scss/utils/utilities";
@use "./assets/scss/utils/variables";

html,
body {
  height: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
}

.action {
  &-approved {
    color: #97fd97;
  }
  &-not-approved {
    color: red;
  }
  &-inserted {
    color: #1b88a7;
  }
}

.border-color-red {
  border-color: red !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.errorMessage {
  background-color: variables.$red;
}

.footer {
  bottom: 0;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.1);
  color: white;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.highlight-row {
  background-color: #ca3a11 !important;
}

.infoMessage {
  background-color: variables.$green;
  color: variables.$black;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.infoMessage,
.errorMessage {
  color: variables.$white;
}

.search-icon {
  font-size: 20px;
  width: 10% !important;
}

.snackbarMessage {
  all: initial;
  border-radius: 20px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
