@use "@angular/material" as mat;
@use "../utils/colors";
@use "../themes/dark-theme";

@mixin light-drawer-theme($theme) {
  @include default-drawer-theme();

  .drawer {
    background-image: linear-gradient(colors.color-get(dark-theme.$dark-theme-accent, 500, 1), colors.color-get(dark-theme.$dark-theme-foreground, 500, 1));
  }

  .mat-drawer-container {
    color: mat.get-color-from-palette(colors.$rina-darkblue, 500, 0.7);
    background-color: mat.get-color-from-palette(colors.$rina-grayweb, 500);
  }
}

@mixin dark-drawer-theme($theme) {
  @include default-drawer-theme();

  .drawer {
    background-image: linear-gradient(colors.color-get(dark-theme.$dark-theme-primary, 400, 1), colors.color-get(dark-theme.$dark-theme-primary, 500, 1));
  }

  .mat-drawer-container {
    color: mat.get-color-from-palette(dark-theme.$dark-theme-primary, 50, 0.7);
    background-color: colors.color-get(dark-theme.$dark-theme-primary, 800, 1);
  }
}

@mixin default-drawer-theme() {
  .mat-drawer-container {
    background-color: #f3f3f3;
  }

  .drawer {
    color: white;
    width: 80px;
  }

  .mat-drawer-side {
    border-right: none;
  }
}
