// --------------------------------------------------
// Flexbox SASS mixins
// The spec: http://www.w3.org/TR/css3-flexbox
// --------------------------------------------------

// Flexbox display
@mixin flexbox() {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

@mixin inline-flex() {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin flex-center() {
  @include flexbox();
  @include justify-content(center !important);
  @include align-items(center !important);
}

@mixin flex-start() {
  @include flexbox();
  @include justify-content(flex-start !important);
}

@mixin flex-end() {
  @include flexbox();
  @include justify-content(flex-end !important);
}

@mixin flex-around() {
  @include flexbox();
  @include justify-content(space-around !important);
}

@mixin flex-between() {
  @include flexbox();
  @include justify-content(space-between !important);
}

@mixin flex-start-center() {
  @include flexbox();
  @include justify-content(flex-start !important);
  @include align-items(center !important);
}

@mixin flex-end-center() {
  @include flexbox();
  @include justify-content(flex-end !important);
  @include align-items(center !important);
}

@mixin flex-end-start() {
  @include flexbox();
  @include justify-content(flex-end !important);
  @include align-items(flex-start !important);
}

@mixin flex-between-center() {
  @include flexbox();
  @include justify-content(space-between !important);
  @include align-items(center !important);
}

@mixin flex-between-start() {
  @include flexbox();
  @include justify-content(space-between !important);
  @include align-items(flex-start !important);
}

@mixin flex-between-end() {
  @include flexbox();
  @include justify-content(space-between !important);
  @include align-items(flex-end !important);
}

@mixin flex-between-stretch() {
  @include flexbox();
  @include justify-content(space-between !important);
  @include align-items(stretch !important);
}

@mixin flex-inline-center() {
  @include inline-flex();
  @include justify-content(center !important);
  @include align-items(center !important);
}

@mixin flex-around-center() {
  @include flexbox();
  @include justify-content(space-around !important);
  @include align-items(center !important);
}

@mixin flex-column() {
  @include flexbox();
  @include flex-direction(column !important);
}

@mixin flex-column-center() {
  @include flexbox();
  @include flex-direction(column !important);
  @include justify-content(center !important);
  @include align-items(center !important);
}

@mixin flex-align-center() {
  @include flexbox();
  @include align-items(center !important);
}

/* Add Custom mixins here */
/* ********************************************************************************************************************************* */

/* ********************************************************************************************************************************* */

// #region Global Flexbox classes
.flex {
  @include flexbox();
}

.flex-center {
  @include flex-center();
}

.flex-start {
  @include flex-start();
}

.flex-end {
  @include flex-end();
}

.flex-around {
  @include flex-around();
}

.flex-between {
  @include flex-between();
}

.flex-start-center {
  @include flex-start-center();
}

.flex-end-center {
  @include flex-end-center();
}

.flex-end-start {
  @include flex-end-start();
}

.flex-between-center {
  @include flex-between-center();
}

.flex-between-start {
  @include flex-between-start();
}

.flex-between-end {
  @include flex-between-end();
}

.flex-between-stretch {
  @include flex-between-stretch();
}

.flex-inline-center {
  @include flex-inline-center();
}

.flex-around-center {
  @include flex-around-center();
}

.flex-column {
  @include flex-column();
}

.flex-column-center {
  @include flex-column-center();
}

.flex-align-center {
  @include flex-align-center();
}
// #endregion

/* Add Custom Classes here */
/* ********************************************************************************************************************************* */
.action-wrapper {
  @include flexbox();
  justify-content: end;
  gap: 10px;
}

.flex-1 {
  @include flex(1); // Make each form field take up equal space
}

.flex-2 {
  @include flex(2);
}

.flex-direction-column {
  @include flex-direction(column);
}

.flex-direction-row {
  @include flex-direction(row);
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.row {
  @include flexbox();
  justify-content: space-between;
}

/* ********************************************************************************************************************************* */
