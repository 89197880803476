@use "@angular/material" as mat;
@use "../utils/colors";
@use "../utils/typography";

$dark-theme-primary: mat.define-palette(colors.$rina-darkgray, 50, 100, 500); // most widely used across all screen and components
$dark-theme-accent: mat.define-palette(colors.$rina-blue, 50, 100, 500, 900); // floating action color and interactive element
$dark-theme-foreground: mat.define-palette(colors.$rina-lightblue, 50, 100, 500, 900); // for text and icons
$dark-theme-warn: mat.define-palette(colors.$rina-warn, 50, 100, 500, 900);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-theme-primary,
      accent: $dark-theme-accent,
      warn: $dark-theme-warn,
    ),
    typography: typography.$cube-typography,
    density: 0,
  )
);

// Phase 2
.dark-theme {
  .delete-color {
    color: rgba(234, 234, 234, 0.7);
  }

  .dialog-content {
    color: rgba(234, 234, 234, 0.7);
  }

  .dialog-close {
    color: rgba(234, 234, 234, 0.7);
  }

  .edit-color {
    color: rgba(234, 234, 234, 0.7);
  }

  .footer {
    background-color: #333535;
  }

  .input-color {
    background-color: #4c4e4e;
    width: 100%;
  }

  .mat-icon {
    color: white;
  }

  .mdc-tab__text-label {
    color: white !important;
    font-weight: 600;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: black;
  }

  .mat-icon {
    color: white;
  }

  .table-column {
    background-color: #4c4e4e;
    border-right-width: 0;
    border: 1px solid #ffffff61;
    color: white;
    padding: 6px 8px 6px 8px;
    text-align: left;

    &:first-child {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }

    &:last-child {
      border-bottom-right-radius: 5px;
      border-right: 1px solid #ffffff61;
      border-top-right-radius: 5px;
    }
  }
  .text {
    color: white;
  }

  textarea {
    background-color: #424242;
    color: white;
  }

  .text {
    color: white;
  }

  textarea {
    background-color: #424242;
    color: white;
  }

  .text {
    color: white;
  }

  textarea {
    background-color: #424242;
    color: white;
  }

  .title {
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  .title-color {
    color: rgba(234, 234, 234, 0.7) !important;
  }
}
