@use "@angular/material" as mat;
@use "../utils/colors";

@mixin light-cube-home-theme($theme) {
  @include default-cube-home-theme($theme);

  .cube-container {
    .sidenav,
    .drawer {
      background: linear-gradient(mat.get-color-from-palette(map-get($theme, primary), 800, 1), mat.get-color-from-palette(colors.$rina-foreground), 400, 1);
    }
  }

  .cube-toolbar,
  .toolbar-user-btn {
    width: 100%;
    background: mat.get-color-from-palette(colors.$rina-grayweb, 500);
    color: mat.get-color-from-palette(colors.$rina-darkblue, 500);
  }
}

@mixin dark-cube-home-theme($theme) {
  @include default-cube-home-theme($theme);

  .cube-container {
    .sidenav,
    .drawer {
      background: linear-gradient(mat.get-color-from-palette(map-get($theme, primary), 800, 1), mat.get-color-from-palette(colors.$rina-foreground), 400, 1);
    }
  }

  .cube-toolbar,
  .toolbar-user-btn {
    width: 100%;
    background: mat.get-color-from-palette(map-get($theme, primary), 900);
    color: mat.get-color-from-palette(map-get($theme, primary, contrast), 900);
  }
}

@mixin default-cube-home-theme($theme) {
  .cube-container {
    // .sidenav,
    // .drawer {
    //   background-image: linear-gradient(
    //     mat.get-color-from-palette(map-get($theme, accent), 500, 1),
    //     mat.get-color-from-palette(colors.$rina-foreground),
    //     500,
    //     1
    //   );
    // }

    .sidenav {
      width: 250px;
    }

    .drawer {
      width: 80px;
    }
  }

  mat-toolbar.bg-logo,
  mat-toolbar.bg-logo.bg0f273c {
    position: relative;
    background: #0f273c !important;

    h1.logo {
      font-size: 20px;
      color: #ffffff;
      margin-left: 15px;
    }

    a.home {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .sidebar {
    width: 300px;
  }

  .content {
    margin-top: 2px;
  }

  .example-margin {
    margin: 0 10px;
  }

  .today {
    width: 100%;
    height: 22%;
    position: absolute;
    background: "#17161642";
    z-index: 1;
  }

  .today-time {
    margin: 0;
    color: white;
    z-index: 2;
  }

  .today-date {
    margin: 0;
    color: white;
    z-index: 2;
  }
}
