@use "@angular/material" as mat;

@use "./components/cube-dialog";
@use "./components/cube-drawer";
@use "./components/cube-home";
@use "./components/cube-sidemenu";
@use "./components/cube-toolbar";
@use "./components/cube-user-menu";
@use "./components/cube-mat";

@use "./themes/light-theme.scss";
@use "./themes/dark-theme.scss";
@use "./utils/colors.scss";
@use "./utils/typography.scss";

@include mat.core();

.light-theme {
  @include mat.all-component-colors(light-theme.$light-theme);
  @include mat.all-component-themes(light-theme.$light-theme);

  @include cube-dialog.light-dialog-theme(light-theme.$light-theme);
  @include cube-drawer.light-drawer-theme(light-theme.$light-theme);
  @include cube-home.light-cube-home-theme(light-theme.$light-theme);
  @include cube-sidemenu.light-cube-sidemenu-theme(light-theme.$light-theme);
  @include cube-toolbar.light-cube-toolbar-theme(light-theme.$light-theme);
  @include cube-user-menu.light-cube-usermenu-theme(light-theme.$light-theme);
  @include cube-mat.light-cube-mat-theme(light-theme.$light-theme);
}

.dark-theme {
  @include mat.all-component-colors(dark-theme.$dark-theme);
  @include mat.all-component-themes(dark-theme.$dark-theme);

  @include cube-dialog.dark-dialog-theme(dark-theme.$dark-theme);
  @include cube-drawer.dark-drawer-theme(dark-theme.$dark-theme);
  @include cube-home.dark-cube-home-theme(dark-theme.$dark-theme);
  @include cube-sidemenu.dark-cube-sidemenu-theme(dark-theme.$dark-theme);
  @include cube-toolbar.dark-cube-toolbar-theme(dark-theme.$dark-theme);
  @include cube-user-menu.dark-cube-usermenu-theme(dark-theme.$dark-theme);
  @include cube-mat.dark-cube-mat-theme(dark-theme.$dark-theme);
}
