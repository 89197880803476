@use "@angular/material" as mat;

@use "../themes/light-theme";
@use "../themes/dark-theme";
@use "../utils/colors";
@use "../utils/typography";

@mixin light-dialog-theme($theme) {
  .user-dialog {
    // color: white;
    background: white;
  }
}

@mixin dark-dialog-theme($theme) {
  .user-dialog {
    color: white;
    background: rgba(35, 31, 32, 1);
  }
}
